<template>
    <nav style="display:flex;">
			<div class="mx-3" 
			v-bind:style="{
				'color': item.name==selectedItem.name? '#2962ff': 'black',
				'text-decoration': item.name==selectedItem.name? 'none': 'underline'
			}"
			style="cursor: pointer"
			v-for="item in displayModules" :key="item.name" 
			>
             <p @click="changePage(item)">{{item.name}}</p>
            </div>
        </nav>
</template>

<script>

export default {
	name: 'WorkerDetailsNavbar',


	props: {
		title: String,
		changePageDisabled: {
			type: Boolean,
			default: false
		},
	},

	data: function() {
		return {
			permissions: null,
			selectedItem: {
                permission: null,
                name: "Cards",
                path: "cards"
            },
            modules: [
                {
                    permission: 'workers_view',
                    name: "Personal data",
                    path: "workerPersonalData"
                },
                {
                    permission: 'view_workerprojectassignment',
                    name: "Projects",
                    path: "workerProjects"
                },
                {
                    permission: 'view_card',
                    name: "Cards",
                    path: "cards"
                },
            ],
			nextPage: null
		}
	},
	computed: {
		displayModules(){
			let displayModules=[]
			if(this.permissions && this.modules){
				for(let item of this.modules){
					if(this.permissions.includes(item.permission) || item.permission==null){
						displayModules.push(item)
					}
				}
			}
			return displayModules
		}
	},
	beforeMount(){
		if(localStorage.getItem('permissions') !== null){
			this.permissions = JSON.parse(localStorage.permissions);
		}
	},
	mounted(){
		this.$emit("set-state", "default");
        this.selectedItem=this.modules.find(item => item.path==this.$route.name)
	},

	watch: {
	},

	methods: {
		changePage(item){
			if(this.changePageDisabled){
				this.nextPage=item
				this.$emit('change-page-clicked');
			}
			else{
				this.selectedItem=item
				this.$router.push({name: item.path})
			}
		},
		confirmChangePageCallback(){
			this.selectedItem=this.nextPage
			this.$router.push({name: this.nextPage.path})
			this.nextPage=null
		}
    }
};
</script>

<style scoped>

.v-navigation-drawer {
	max-width: 80%;
}


	.appBarInfo {
		margin-left: 250px;
		display: flex;
		align-items: center;
	}

@media only screen and (max-width: 960px) { 
	.appBarInfo {
		margin-left: 0;
		display: flex;
		align-items: center;
	}
}

</style>